.App {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
}

.App-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.btn {
  background-color: #1f51ff;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 24px;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#check_in{
  background-color: green;
}
#check_out{
  background-color: red;
}
.App-logo {
  height: 20vmin;
  pointer-events: none;
}
